import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faBackwardStep,
  faCheck,
  faEye,
  faForwardStep,
  faSpinner,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { formatTimestamp, getPaimentColor } from "../../components/utils";
import modalstyles from "../../components/modal/modal.module.css";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import { Modal as BootStrapModal } from "react-bootstrap";
import SaleDetailModal from "../closures/sale_detail/sale_detail";
import { formatPrice } from "../../../utils/utils";
import { queryClient } from "../../../App";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";
import usePermissions from "../../../hooks/usePermissions";

export default function SalesDetail() {
  const [rows, setRows] = useState([]);
  const [selectedSale, setSelectedSale] = useState({});
  const [showCancel, setShowCancel] = useState(false);
  const [showSale, setShowSale] = useState(false);
  const [showUnCancel, setShowUnCancel] = useState(false);
  // const permissions = parseInt(localStorage.getItem("permissions"));
  const permissions = usePermissions();

  const [form, setForm] = useState({
    reason: "",
    detail: "",
  });

  const [currentSaleStatus, setCurrentSaleStatus] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastSaleId = rows.length > 0 ? rows[0].id : 0;
      axiosInstance
        .get(`sale/?all=${true}&starting_sale_id=${lastSaleId}`)
        .then((response) => {
          if (response.status === 200) {
            setRows([...response.data.results, ...rows]);
          }
        });
    }, 85000);

    return () => clearInterval(interval);
  }, [rows]);

  const getStatus = (status) => {
    if (status === 0)
      return (
        <div
          style={{
            backgroundColor: "#fa9f43",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Pending
        </div>
      );
    else if (status === 1)
      return (
        <div
          style={{
            backgroundColor: "#eeda53",
            textAlign: "center",
            borderRadius: "6px",
            color: "#fff",
          }}
        >
          In Preparation
        </div>
      );
    else if (status === 2)
      return (
        <div
          style={{
            backgroundColor: "#439ffa",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          In Delivery
        </div>
      );
    else if (status === 4)
      return (
        <div
          style={{
            backgroundColor: "#41d935",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Completed
        </div>
      );
    else if (status === 5)
      return (
        <div
          style={{
            backgroundColor: "#f34933",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Cancelled
        </div>
      );
    return "Unknown";
  };

  const backStep = (data) => {
    let saleState = [...currentSaleStatus];

    let findIndex = saleState.findIndex((item) => item?.id === data?.id);

    if (findIndex !== -1) {
      saleState[findIndex] = {
        ...saleState[findIndex],
        state: +saleState[findIndex]?.state - 1,
      };
    } else {
      saleState.push({
        id: data?.id,
        state: +data?.status - 1,
        isLoading: true,
      });
    }

    setCurrentSaleStatus(saleState);

    findIndex = saleState.findIndex((item) => item?.id === data?.id);

    axiosInstance
      .put("sale/" + data.id + "/previous_step/")
      .then((response) => {
        // window.location.reload();

        console.log({ response });
        if (response?.status === 200) {
          toast("Status changer", ToastTypes.SUCCESS);
          queryClient.invalidateQueries({
            queryKey: ["tableData-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-deatil-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-owrner-sale-list"],
          });
        } else {
          saleState[findIndex] = {
            ...saleState[findIndex],
            state: +saleState[findIndex]?.state,
            isLoading: false,
          };
          toast("Status non-changer", ToastTypes.ERROR);
        }
      })
      .finally(() => {
        // saleState[findIndex] = {
        //   ...saleState[findIndex],
        //   isLoading: false,
        // };
        // setCurrentSaleStatus(saleState);
      });
  };

  const forwardStep = (data) => {
    let saleState = [...currentSaleStatus];

    let findIndex = saleState.findIndex((item) => item?.id === data?.id);

    if (findIndex !== -1) {
      saleState[findIndex] = {
        ...saleState[findIndex],
        state:
          saleState[findIndex]?.state === 2
            ? saleState[findIndex]?.state + 2
            : saleState[findIndex]?.state + 1,
      };
    } else {
      saleState.push({
        id: data?.id,
        state: data?.status === 2 ? data?.status + 2 : data?.status + 1,
        isLoading: true,
      });
    }
    setCurrentSaleStatus(saleState);

    // findIndex = saleState.findIndex((item) => item?.id === data?.id);

    axiosInstance
      .put("sale/" + data.id + "/next_step/")
      .then((response) => {
        // window.location.reload();

        if (response?.status === 200) {
          // saleState[findIndex] = {
          //   ...saleState[findIndex],
          //   state: saleState[findIndex]?.state + 1,
          //   isLoading: false,
          // };
          toast("Status changer", ToastTypes.SUCCESS);
          queryClient.invalidateQueries({
            queryKey: ["tableData-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-deatil-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-owrner-sale-list"],
          });
        } else {
          saleState[findIndex] = {
            ...saleState[findIndex],
            state: +saleState[findIndex]?.state,
          };
          toast("Status non-changer", ToastTypes.ERROR);
        }
      })
      .finally(() => {
        // saleState[findIndex] = {
        //   ...saleState[findIndex],
        //   isLoading: false,
        // };
        // setCurrentSaleStatus(saleState);
      });
  };

  const view = (data) => {
    setShowSale(true);
    setSelectedSale(data);
  };

  const cols = [
    {
      title: "Date",
      internal: "date",
      code: (data, row) => formatTimestamp(row),
    },
    { title: "Reference Number", internal: "reference", filter: true },
    {
      title: "Client",
      internal: "user.user_data",
      filter: true,
      code: (data, row) => row.name + " " + row.lastname,
    },
    { title: "Orders", internal: "user_sales", filter: true },
    {
      title: "District",
      internal: "district.name",
      filter: true,
      filterInternal: "district",
    },
    {
      title: "Warehouse",
      internal: "warehouse.name",
      filter: true,
      filterInternal: "warehouse",
      code: (row, data) => {
        return row.supplier ? "-" : data;
      },
    },
    {
      title: "Biller",
      internal: "supplier.name",
      filter: true,
      filterInternal: "supplier",
      code: (row, data) => {
        return row.supplier ? data : "-";
      },
    },
    { title: "Delivery Fee", internal: "fee" },
    { title: "Points Used", internal: "points_used" },
    {
      title: "Total",
      internal: "sub_total",
      code: (data, row) => {
        let total_variant_price = 0;
        // data.items.forEach((item) => {
        //   let item_variant_price = 0;
        //   item.variant_items.forEach((variant) => {
        //     item_variant_price += variant.variant_item.price * item.qty;
        //   });
        //   total_variant_price += item_variant_price;
        // });
        // return formatPrice(data.sub_total + data.fee + total_variant_price);
        // const color = data.points_used > 0 ? "#fa9f43" : "#000";
        // return (
        //   <span style={{ color }}>
        //     {formatPrice(data.sub_total + data.fee + total_variant_price)}
        //   </span>
        // );
        const total =
          +data.points_used > 0
            ? parseInt(data.points_used) === parseInt(row + data.fee)
              ? 0
              : data?.total_payed
              ? parseInt(data?.total_payed)
              : parseInt(row + data.fee)
            : data?.total_payed
            ? parseInt(data?.total_payed)
            : parseInt(row + data.fee);
        const color = data.points_used > 0 ? "#fa9f43" : "#000";
        return <span style={{ color }}>{formatPrice(total)}</span>;
      },
    },
    {
      title: "Status",
      internal: "status",
      filter: true,
      code: (data, row) =>
        getStatus(
          currentSaleStatus.find((item) => data?.id === item?.id)
            ? currentSaleStatus.find((item) => data?.id === item?.id)?.state
            : parseInt(row)
        ),
    },
    {
      title: "Paiment",
      internal: "paiment_mode",
      filter: true,
      code: (data, row) => getPaimentColor(data.paiment_mode),
    },

    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        const not_today =
          new Date(data.date).toDateString() !== new Date().toDateString();
        const item = currentSaleStatus.find((item) => data?.id === item?.id);
        const disabled =
          (item && (item?.state === 4 || item?.state === 5)) ||
          data?.status === 4 ||
          data?.status === 5;
        return (
          <div className={"d-flex justify-content-between"} style={{ gap: 5 }}>
            {permissions === 1 && (
              <div
                role={"button"}
                onClick={() => (disabled ? null : backStep(data))}
                style={{
                  opacity: disabled ? 0.3 : 1,
                }}
              >
                <FontAwesomeIcon icon={faBackwardStep} color={"#9EA3AC"} />
              </div>
            )}
            <div
              role={"button"}
              onClick={() => (disabled ? null : forwardStep(data))}
              style={{
                opacity: disabled ? 0.3 : 1,
              }}
            >
              <FontAwesomeIcon icon={faForwardStep} color={"#9EA3AC"} />
            </div>

            <div role={"button"} onClick={() => view(data)}>
              <FontAwesomeIcon icon={faEye} color={"#9EA3AC"} />
            </div>

            {!not_today && (
              <div
                role={"button"}
                onClick={() => {
                  setSelectedSale(data);
                  if (item) {
                    if (item?.state !== 5) {
                      setShowCancel(true);
                    } else {
                      setShowUnCancel(true);
                    }
                  } else {
                    data.status !== 5
                      ? setShowCancel(true)
                      : setShowUnCancel(true);
                  }
                }}
              >
                {item ? (
                  item?.state !== 5 ? (
                    <FontAwesomeIcon icon={faX} color={"#EF4444"} />
                  ) : (
                    permissions === 1 && (
                      <FontAwesomeIcon icon={faCheck} color={"#44ef94"} />
                    )
                  )
                ) : data.status !== 5 ? (
                  <FontAwesomeIcon icon={faX} color={"#EF4444"} />
                ) : (
                  permissions === 1 && (
                    <FontAwesomeIcon icon={faCheck} color={"#44ef94"} />
                  )
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  // const cancel = () => {
  //     axiosInstance.post('sale/' + selectedSale?.id + '/cancel/').then((response) => {
  //         window.location.reload()
  //     });
  // }

  const cancellationReasons = [
    { id: "Client Injoignable", name: "Client Injoignable" },
    { id: "Doublon", name: "Doublon" },
    { id: "Produit Manquant", name: "Produit Manquant" },
    { id: "Adresse Incorrecte", name: "Adresse Incorrecte" },
    { id: "Retard Commande", name: "Retard Commande" },
    { id: "Autre", name: "Autre" },
  ];

  const validateCancelForm = () => {
    const { reason, detail } = form;
    return reason && detail;
  };

  const cancel = () => {
    // console.log({
    //   reason: form.reason,
    //   detail: form.detail,
    // });

    if (!showUnCancel) {
      if (!validateCancelForm()) {
        alert("Tous les champs doivent être remplis.");
        return;
      }
    }

    let saleState = [...currentSaleStatus];

    let findIndex = saleState.findIndex(
      (item) => item?.id === selectedSale?.id
    );

    if (findIndex !== -1) {
      saleState[findIndex] = {
        ...saleState[findIndex],
        state: saleState[findIndex]?.state === 5 ? 0 : 5,
      };
    } else {
      saleState.push({
        id: selectedSale?.id,
        state: selectedSale?.status === 5 ? 0 : 5,
        isLoading: true,
      });
    }
    setCurrentSaleStatus(saleState);

    findIndex = saleState.findIndex((item) => item?.id === selectedSale?.id);

    setShowCancel(false);
    setShowUnCancel(false);
    axiosInstance
      .post("sale/" + selectedSale?.id + "/cancel/", {
        reason: form.reason,
        detail: form.detail,
      })
      .then((response) => {
        // window.location.reload();

        if (response?.status === 200) {
          queryClient.invalidateQueries({
            queryKey: ["tableData-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-deatil-sale-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-owrner-sale-list"],
          });
          toast("Status changer", ToastTypes.SUCCESS);
        } else {
          toast("Status non-changer", ToastTypes.ERROR);
        }
      })
      .catch(() => {
        toast("Status non-changer", ToastTypes.ERROR);
      })
      .finally(() => {
        saleState[findIndex] = {
          ...saleState[findIndex],
          isLoading: false,
        };
        setCurrentSaleStatus(saleState);
      });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <BootStrapModal show={showUnCancel} centered={true}>
        <div className={modalstyles.header}>
          <div>Uncancel Sale</div>
          <div>Are you sure?</div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton
            onClick={() => setShowUnCancel(false)}
            name={"Back"}
          />
          <div className={"ms-3"}>
            <DangerButton name={"Uncancel Sale"} onClick={() => cancel()} />
          </div>
        </div>
      </BootStrapModal>
      <BootStrapModal show={showCancel} centered={true}>
        <div className={modalstyles.header}>
          <div>
            {selectedSale?.status === 5 ? "Uncancel Sale" : "Cancel Sale"}
          </div>
          <div>Are you sure?</div>
        </div>

        <div className={modalstyles.body}>
          <div className="mb-3">
            <SelectInput
              options={cancellationReasons}
              form={form}
              setForm={setForm}
              name={"reason"}
              internalName={"reason"}
            />
          </div>
          <div className="mb-3">
            <TextInput
              name={"detail"}
              placeholder={"Provide additional details..."}
              form={form}
              setForm={setForm}
              internalName={"detail"}
            />
          </div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton onClick={() => setShowCancel(false)} name={"Back"} />
          <div className={"ms-3"}>
            <DangerButton
              name={
                selectedSale?.status === 5 ? "Uncancel Sale" : "Cancel Sale"
              }
              onClick={cancel}
              //   disabled={!form.reason}
              disabled={!form.reason || !form.detail}
            />
          </div>
        </div>
      </BootStrapModal>

      <BootStrapModal show={showSale} centered={true} size={"lg"}>
        <div>
          <div style={{ padding: "16px" }}>
            <div
              className={"mb-2"}
              style={{ fontWeight: "600", fontSize: "20px", color: "#334155" }}
            >
              Sale Details
            </div>
            <SaleDetailModal data={selectedSale} />
          </div>
          <div className={styles.footer}>
            <div className={"ms-3"}>
              <PrimaryButton
                name={"Close"}
                onClick={() => setShowSale(false)}
              />
            </div>
          </div>
        </div>
      </BootStrapModal>

      <div className={`${styles.container} me-4`}>
        <TableView
          noCreate={true}
          defaultFilters={{ all: true }}
          title={"sales"}
          fetchUrl={"sale"}
          pull={setRows}
          cols={cols}
          rows={rows}
          useReactQuery={"detail-sale-list"}
        />
      </div>
    </div>
  );
}
