import React, { useState, useRef, useEffect } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import axiosInstance from "../../../AxiosInstance";
import "./ai.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCog,
  faEnvelope,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const SUGGESTED_PROMPTS = [
  {
    icon: faUsers,
    title: "Nombre de commandes d'aujourd'hui",
    description: "Combien de commandes ont été passées aujourd'hui ?",
  },
  {
    icon: faEnvelope,
    title: "Nombre de commandes passées avec des points",
    description: "Combien de commandes ont été passées avec des points ?",
  },
  {
    icon: faCog,
    title: "Le warehouse le plus actif",
    description: "Quel warehouse a plus de commandes ?",
  },
  {
    icon: faCog,
    title: "Les produits les plus vendus aujourd'hui",
    description: "Quels sont les produits les plus vendus aujourd'hui ?",
  },
];

function AIchat() {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState(""); // Pour stocker le nom de l'utilisateur
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Récupérer le nom de l'utilisateur (à adapter selon votre système d'authentification)
    const fetchUserName = async () => {
      try {
        const response = await axiosInstance.get("current_user/");
        setUserName(response.data.first_name || "");
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };
    fetchUserName();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const userMessage = {
      role: "user",
      content: inputMessage,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputMessage("");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post("ask/", {
        question: inputMessage,
      });

      const aiMessage = {
        role: "assistant",
        content:
          response.data.response ||
          response.data.answer ||
          "Désolé, je n'ai pas pu traiter votre demande.",
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = {
        role: "assistant",
        content: "Désolé, veuiller reformuler votre question, Pour que je puisse vous aider",
          // "Désolé, une erreur s'est produite lors de la communication avec l'IA. Veuillez réessayer.",
        timestamp: new Date(),
        isError: true,
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromptClick = (prompt) => {
    setInputMessage(prompt.description);
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="">
      <AdminSidebar />
      <AdminHeader />
      <div className={`${styles.container} me-4 pb-4`}>
        <div className="chat-container">
          {messages.length === 0 ? (
            <div className="ai-welcome">
              <h1>Bonjour {userName || ""},</h1>
              <h2>Que souhaitez-vous savoir ?</h2>
              <p className="text-muted">
                Utilisez l'un des prompts les plus courants ci-dessous ou
                écrivez votre propre question
              </p>

              <div className="prompts-container" style={{marginBottom: 100}}>
                {SUGGESTED_PROMPTS.map((prompt, index) => (
                  <div
                    key={index}
                    className="prompt-card"
                    onClick={() => handlePromptClick(prompt)}
                  >
                    <div className="prompt-icon">
                      <FontAwesomeIcon icon={prompt.icon} />
                    </div>
                    <div className="prompt-content">
                      <h3>{prompt.title}</h3>
                      <p>{prompt.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="chat-messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.role === "user" ? "user-message" : "ai-message"
                  } ${message.isError ? "error-message" : ""}`}
                >
                  <div className="message-content">
                    <div className="message-text">{message.content}</div>
                    <div className="message-timestamp">
                      {formatTimestamp(message.timestamp)}
                    </div>
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="message ai-message">
                  <div className="message-content">
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          )}

          <form
            onSubmit={handleSendMessage}
            className="chat-input-container"
            style={{
              marginBottom: 10,
              position: "fixed",
              bottom: 10,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Posez votre question..."
              className="chat-input"
              disabled={isLoading}
            />
            <button
              type="submit"
              className="send-button"
              disabled={!inputMessage.trim() || isLoading}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AIchat;
