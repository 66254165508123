import React, { useState } from "react";
import ShopHeader from "../components/header/header";
import PageTitle from "../components/page_title/page_title";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import { formatTimestamp } from "../../admin/components/utils";
import styles from "./my_orders.module.css";
import OrderDetail from "../components/order_detail/order_detail";
import { formatPrice } from "../../utils/utils";
import { useQuery } from "@tanstack/react-query";

const fetchOrders = async () => {
  const response = await axiosInstance.get("current_user_orders/");
  return response.data;
};

export default function MyOrders() {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const {
    data: orders = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["orders"],
    queryFn: fetchOrders,
  });

  React.useEffect(() => {
    document.title = "Mes commandes | Bring Me";
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return {
          label: "En attente",
          color: "#fa9f43"  // Orange
        };
      case 1:
        return {
          label: "Confirmée",
          color: "#00cfe8"  // Cyan
        };
      case 2:
        return {
          label: "En préparation",
          color: "#7367f0"  // Purple
        };
      case 3:
        return {
          label: "En livraison",
          color: "#1890ff"  // Blue
        };
      case 4:
        return {
          label: "Livrée",
          color: "#28c76f"  // Green
        };
      case 5:
        return {
          label: "Annulée",
          color: "#ea5455"  // Red
        };
      default:
        return {
          label: "Inconnu",
          color: "#82868b"  // Grey
        };
    }
  };

  const getPaymentMode = (mode) => {
    switch (mode) {
      case "cash":
        return {
          label: "Paiement en espèces",
          image: "../assets/cash.png",
          color: "#28c76f"
        };
      case "wave":
        return {
          label: "Wave",
          image: "../assets/wave.png",
          color: "#4484FF"
        };
      case "om":
        return {
          label: "Orange Money",
          image: "../assets/om.png",
          color: "#FF7900"
        };
      case "maxit":
        return {
          label: "MaxIt",
          image: "../assets/maxit.png",
          color: "#00f"
        };
      default:
        return {
          label: mode,
          image: "../assets/default.png",
          color: "#82868b"
        };
    }
  };

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Chargement...</span>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="alert alert-danger m-5" role="alert">
        Une erreur est survenue: {error.message}
      </div>
    );
  }

  return (
    <div>
      <ShopHeader />

      <OrderDetail
        showModal={showModal}
        setShowModal={setShowModal}
        order={selectedOrder}
      />

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle name={"Mes commandes"} />

        <div className={`mx-2 pb-5 mt-4 ${styles.innerContainer}`}>
          {orders.map((order) => (
            <div
              key={order.reference}
              className={`${styles.container} mb-3`}
              onClick={() => {
                setShowModal(true);
                setSelectedOrder(order);
              }}
            >
              <div
                className={`${styles.title} p-3 d-flex justify-content-between align-items-center`}
              >
                <div>
                  <b>Commande</b> ({order.reference})
                </div>
                <div className={"fw-bold"}>
                  {order.supplier
                    ? order.supplier.name
                    : order.warehouse?.name || "Epicerie en ligne"}
                </div>
                <div
                  className="px-3 py-1 rounded-pill"
                  style={{
                    color: "white",
                    backgroundColor: getStatus(order.status).color,
                    fontSize: "0.9em"
                  }}
                >
                  {getStatus(order.status).label}
                </div>
              </div>

              <div className="px-3 py-2">
                <div className="mb-3">
                  <h6 className="mb-2">Articles commandés:</h6>
                  {order.items.map((item) => (
                    <div
                      key={item.id}
                      className="d-flex justify-content-between align-items-center mb-2"
                    >
                      <div>
                        {item.qty}x {item.product_name}
                      </div>
                      <div>{formatPrice(item.sub_total)} FCFA</div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <b>Adresse:</b>
                  </div>
                  <div>{order.address}</div>
                </div>

                {order.indications && (
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <b>Indications:</b>
                    </div>
                    <div>{order.indications}</div>
                  </div>
                )}

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <b>Date:</b>
                  </div>
                  <div>{formatTimestamp(order.date)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <b>Mode de paiement:</b>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={getPaymentMode(order.paiment_mode).image}
                      alt={getPaymentMode(order.paiment_mode).label}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                        objectFit: "contain"
                      }}
                    />
                    <span style={{ color: getPaymentMode(order.paiment_mode).color }}>
                      {getPaymentMode(order.paiment_mode).label}
                    </span>
                  </div>
                </div>

                {order.points_earned > 0 && (
                  <div className="d-flex justify-content-between mb-2" style={{ color: "#28c76f" }}>
                    <div>
                      <b>Points gagnés:</b>
                    </div>
                    <div>{order.points_earned} points</div>
                  </div>
                )}

                {order.points_used > 0 && (
                  <div className="d-flex justify-content-between mb-2" style={{ color: "#fa9f43" }}>
                    <div>
                      <b>Points utilisés:</b>
                    </div>
                    <div>{order.points_used} points</div>
                  </div>
                )}

                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <b>Frais de livraison:</b>
                  </div>
                  <div>{formatPrice(order.fee)} FCFA</div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <b>Total:</b>
                  </div>
                  <div
                    className="fw-bold"
                    style={{
                      color: order?.points_used > 0 ? "#fa9f43" : undefined,
                    }}
                  >
                    {formatPrice((order.sub_total + order.fee) - order?.points_used)} FCFA
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Footer />
      </div>
    </div>
  );
}
