import React, { useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/admin.module.css";
import { formatTimestamp } from "../../components/utils";
import TableView from "../../components/table_view";

function ManualGains() {
  const { state } = useLocation();
  const [rows, setRows] = useState([]);

  const actionReasons = +state?.points
    ? [
        { id: "add", name: "Attribuer" },
        { id: "remove", name: "Retirer" },
      ]
    : [{ id: "add", name: "Attribuer" }];

  const cols = [
    {
      title: "Date",
      internal: "created_at",
      code: (_, row) => formatTimestamp(row),
    },
    {
      title: "Telephone",
      internal: "user_data.phone_number",
      filter: true,
    },
    {
      title: "Prenom nom",
      internal: "user_data.lastname",
      code: (row, data) => <p>{data + " " + row?.user_data?.name}</p>,
      filter: true,
    },
    {
      title: "Utilisables",
      internal: "user_data.points",
      code: (data, row) => (
        <div
          style={{
            fontSize: 16,
            flexDirection: "row",
            display: "flex",
            gap: 10,
          }}
        >
          <b>
            {data?.action_type === "achat" || data?.action_type === "remove"
              ? data?.current_points - data?.points
              : data?.current_points + data?.points}
          </b>
        </div>
      ),
    },
    {
      title: "Points",
      internal: "points",
      code: (data, row) => (
        <b>
          {((data?.action_type === "achat" || data?.action_type === "remove") &&
            "- ") + data?.points}
        </b>
      ),
    },
    {
      title: "Warehouse",
      internal: "warehouse.name",
      code: (data, row) =>
        data?.supplier?.name ? "-" : data?.warehouse?.name || "-",
      filter: true,
    },
    {
      title: "Biller",
      internal: "supplier.name",
      code: (data, row) => data?.supplier?.name || "-",
      filter: true,
    },
    { title: "Reason", internal: "reason" },
    {
      title: "Actions",
      internal: "action_type",
      code: (data, row) =>
        actionReasons.find(
          (reason) =>
            data?.action_type?.toLowerCase() === reason?.id?.toLowerCase()
        )?.name ||
        data?.action_type ||
        "-",
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          defaultFilters={{
            action_type: "add",
          }}
          title={"Historique des attributions"}
          fetchUrl={"points/history"}
          cols={cols}
          pull={setRows}
          rows={rows}
          withIndex={false}
          noCreate={true}
          limit={50}
          useReactQuery={"historique-points-list"}
          refetchInterval={30000}
        />
      </div>
    </div>
  );
}

export default ManualGains;
