import axios from "axios";
import { baseURL } from "../AxiosInstance";
export const sendNotificationOneSignal = async (
  title,
  message,
  externalUserIds
) => {
  console.log("sendNotificationOneSignal -------------------------------");
  const url = "send-notification/";
  const headers = {
    "Content-Type": "application/json",
  };
  console.log("externalUserIds", externalUserIds);

  const payload = {
    title,
    message,
    external_user_ids: externalUserIds,
  };

  try {
    const response = await axios.post(baseURL + url, payload, { headers });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending notification:", error);
    throw error;
  }
};
