import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import purchaseStyles from "./purchase.module.css";
import {
  // DateInput,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
// import MyContext from "../../../MyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import modalstyles from "../../components/modal/modal.module.css";
import { Modal as BootStrapModal } from "react-bootstrap";
import { queryClient } from "../../../App";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../hooks/usePermissions";
import useWarehouse from "../../../hooks/useWarehouse";

export default function PurchaseOrderAutomaticBiller() {
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [form, setForm] = useState({});
  const [timer, setTimer] = useState(null);
  const [foundProducts, setFoundProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const permissions = parseInt(localStorage.getItem("permissions"));
  const permissions = usePermissions();
  // const warehouse_id = parseInt(localStorage.getItem("warehouse_id"));
  const warehouse_id = useWarehouse();

  useEffect(() => {
    if (form["supplier"]) {
      axiosInstance
        .get(`automatic_order/?biller_id=${form["supplier"]}`)
        .then((response) => {
          const warehousesData = response?.data?.data;
          const updatedWarehouses = warehousesData?.map((warehouseData) => ({
            warehouse: warehouseData.warehouse,
            products: warehouseData?.products?.map((product) => ({
              id: product.product.id,
              name: product.product.name,
              cost: product.product.cost,
              fx: product.fx,
              packaging: Math.ceil(
                product.quantity / product.product.packaging
              ),
              quantity: product.quantity,
              originalPackaging: product.product.packaging,
              total: product.product.cost * product.quantity,
              warehouse_quantity:
                product.product.warehouse_quantities[
                  warehouseData.warehouse_slug
                ],
            })),
          }));
          //   setWarehouses(updatedWarehouses);
          setSelectedProducts(updatedWarehouses);
        });
    }
  }, [form["supplier"]]);

  useEffect(() => {
    let newSubtotal = 0;
    selectedProducts?.forEach((product) => {
      newSubtotal += product.total;
    });
    setSubtotal(newSubtotal);
  }, [selectedProducts]);

  // useEffect(() => {
  //   if (timer) {
  //     clearTimeout(timer);
  //   }

  //   if (form["product_name"]?.trim() !== "") {
  //     const newTimer = setTimeout(() => {
  //       axiosInstance
  //         .get(
  //           `product/?max_query=5&no_combos=${true}&name=` +
  //             form["product_name"]
  //         )
  //         .then((response) => {
  //           const found = response?.data?.results;
  //           if (found?.length === 1) {
  //             const only_product_found = found[0];
  //             setSelectedProducts([
  //               ...selectedProducts,
  //               {
  //                 id: only_product_found.id,
  //                 name: only_product_found.name,
  //                 cost: only_product_found.cost,
  //                 packaging: 1,
  //                 quantity: only_product_found.packaging,
  //                 originalPackaging: only_product_found.packaging,
  //                 total: only_product_found.cost * only_product_found.packaging,
  //               },
  //             ]);
  //             setFoundProducts([]);
  //             setForm({ ...form, product_name: "" });
  //           } else {
  //             let data = response?.data?.results;
  //             data.forEach((product) => {
  //               let totalCost = product.cost * product.packaging;
  //               product.total = totalCost;
  //             });
  //             setFoundProducts(data);
  //           }
  //         });
  //     }, 400);

  //     setTimer(newTimer);
  //   }
  // }, [form["product_name"]]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const warehouseResponse = await axiosInstance.get("warehouse/");
        setWarehouses(warehouseResponse?.data);
      } catch (error) {
        setShowError(true);
      }

      try {
        const supplierResponse = await axiosInstance.get("biller/");
        setSuppliers(supplierResponse?.data);
      } catch (error) {
        setShowError(true);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/purchase_order");
    navigate("/admin/purchase_order", { replace: true });
  };

  const submit = () => {
    setLoading(true);
    if (form.supplier === undefined || selectedProducts?.length < 1) {
      setLoading(false);
      setShowError(true);
      return;
    }

    // Filter out warehouses with no products
    const filteredWarehouses = selectedProducts.filter(
      (warehouseData) => warehouseData.products.length > 0
    );

    const new_form = {
      biller_id: form.supplier,
      warehouse_id: form.warehouse,
      products: filteredWarehouses, // Use the filtered list
      payed: form.payed,
    };

    console.log(new_form);
    axiosInstance
      .post("automatic_order/", new_form)
      .then((response) => {
        setLoading(false);
        queryClient.invalidateQueries({
          queryKey: ["pourchase-list"],
        });
        back();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setLoading(false);
        setShowError(true);
      });
  };

  const handleRemoveClick = (warehouseIndex, productIndex) => {
    // Create a copy of the selectedProducts array
    const updatedWarehouses = [...selectedProducts];

    // Remove the product from the specific warehouse
    updatedWarehouses[warehouseIndex].products = updatedWarehouses[
      warehouseIndex
    ].products.filter((_, index) => index !== productIndex);

    // Update the state with the new array
    setSelectedProducts(updatedWarehouses);
  };

  const copyToClipboard = () => {
    const today = new Date().toLocaleDateString();
    const billerName = form.supplier
      ? suppliers.find((supplier) => supplier.id === form.supplier)?.name
      : "Unknown Biller";

    const greeting = "Bonjour, bonne réception de ma commande\n\n";

    const contentToCopy =
      greeting +
      `${today}\n ${billerName}\n\n` +
      selectedProducts
        .map((warehouse) => {
          const productDetails = warehouse.products
            .map((product) => {
              return `${product.quantity} ${product.name}`;
            })
            .join("\n");
          return `${warehouse.warehouse}\n${productDetails}`;
        })
        .join("\n\n");

    navigator.clipboard
      .writeText(contentToCopy)
      .then(() => {
        alert("Content copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <BootStrapModal show={showError} centered={true}>
        <div className={modalstyles.header}>
          <div>Veuillez verifier les champs</div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton onClick={() => setShowError(false)} name={"Back"} />
        </div>
      </BootStrapModal>

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Automatic Purchase</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <SelectInput
              name={"supplier"}
              form={form}
              setForm={setForm}
              options={suppliers}
            />
            <SelectInput
              name={"payed"}
              form={form}
              setForm={setForm}
              options={[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            />
          </div>
          {/* <div className={`${styles.createContainer} p-4`}>
            <TextInput
              form={form}
              setForm={setForm}
              name={"Product"}
              internalName={"product_name"}
            />
            <div>
              {foundProducts?.map((product, index) => (
                <div
                  key={index}
                  className={
                    "d-flex mb-2 px-2 py-2 align-items-center justify-content-between"
                  }
                  style={{
                    borderRadius: "6px",
                    border: "1px #ccc solid",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedProducts([
                      ...selectedProducts,
                      {
                        id: product.id,
                        name: product.name,
                        cost: product.cost,
                        packaging: 1,
                        quantity: product.packaging,
                        originalPackaging: product.packaging,
                        total: product.cost * product.packaging,
                      },
                    ]);
                    setFoundProducts([]);
                    setForm({ ...form, product_name: "" });
                  }}
                >
                  <div>{product.name}</div>
                  <FontAwesomeIcon icon={faPlus} color={"#009F7F"} />
                </div>
              ))}
            </div>
          </div> */}

          <div className={`${styles.createContainer} p-4`}>
            {selectedProducts
              ?.filter((warehouseData) => warehouseData.products.length > 0)
              .map((warehouseData, warehouseIndex) => (
                <div key={warehouseIndex}>
                  <h3>{warehouseData?.warehouse}</h3>
                  <div className={`${styles.createContainer} p-4`}>
                    <div className={"d-flex"} style={{ overflowX: "scroll" }}>
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th>Product</th>
                            <th>Packaging</th>
                            <th>Quantity</th>
                            <th>Unit Cost</th>
                            {/* <th>Expiry Date</th> */}
                            {/* <th>Total</th> */}
                            <th>Fx</th>
                            <th>Stock</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {warehouseData?.products?.map((product, index) => {
                            const handleQuantityChange = (event) => {
                              const updatedProducts = [
                                ...warehouseData.products,
                              ];
                              const value = parseInt(event.target.value);
                              updatedProducts[index] = {
                                ...product,
                                quantity: value,
                                packaging: Math.ceil(
                                  value / product.originalPackaging
                                ),
                                total: value * product.cost,
                              };
                              // Update the selectedProducts state with the modified warehouse
                              setSelectedProducts((prevSelectedProducts) =>
                                prevSelectedProducts?.map((warehouse) =>
                                  warehouse.warehouse ===
                                  warehouseData.warehouse
                                    ? {
                                        ...warehouse,
                                        products: updatedProducts,
                                      }
                                    : warehouse
                                )
                              );
                            };

                            //   const handlePackagingChange = (PackagingQte) => {
                            //     const updatedProducts = [...warehouseData.products];
                            //     const value = parseInt(PackagingQte);
                            //     const newQuantity =
                            //       value * product.originalPackaging;
                            //     updatedProducts[index] = {
                            //       ...product,
                            //       packaging: value,
                            //       quantity: value * product.originalPackaging,
                            //       total: newQuantity * product.cost,
                            //     };
                            //     setSelectedProducts(
                            //       warehouses.map((warehouse) =>
                            //         warehouse.warehouse === warehouseData.warehouse
                            //           ? { ...warehouse, products: updatedProducts }
                            //           : warehouse
                            //       )
                            //     );
                            //   };
                            const handlePackagingChange = (PackagingQte) => {
                              const updatedProducts = [
                                ...warehouseData.products,
                              ];
                              const value = parseInt(PackagingQte);
                              const newQuantity =
                                value * product.originalPackaging;
                              updatedProducts[index] = {
                                ...product,
                                packaging: value,
                                quantity: newQuantity,
                                total: newQuantity * product.cost,
                              };
                              // Update the selectedProducts state with the modified warehouse
                              setSelectedProducts((prevSelectedProducts) =>
                                prevSelectedProducts?.map((warehouse) =>
                                  warehouse.warehouse ===
                                  warehouseData.warehouse
                                    ? {
                                        ...warehouse,
                                        products: updatedProducts,
                                      }
                                    : warehouse
                                )
                              );
                            };
                            const handleCostChange = (event) => {
                              const updatedProducts = [
                                ...warehouseData.products,
                              ];
                              const newCost = parseInt(event.target.value);
                              updatedProducts[index] = {
                                ...product,
                                cost: newCost / updatedProducts[index].quantity,
                                total: newCost,
                              };
                              // Update the selectedProducts state with the modified warehouse
                              setSelectedProducts((prevSelectedProducts) =>
                                prevSelectedProducts?.map((warehouse) =>
                                  warehouse.warehouse ===
                                  warehouseData?.warehouse
                                    ? {
                                        ...warehouse,
                                        products: updatedProducts,
                                      }
                                    : warehouse
                                )
                              );
                            };

                            const handleDateChange = (event) => {
                              const updatedProducts = [
                                ...warehouseData.products,
                              ];
                              updatedProducts[index] = {
                                ...product,
                                expiry_date: event.target.value,
                              };
                              setSelectedProducts(
                                warehouses?.map((warehouse) =>
                                  warehouse.warehouse ===
                                  warehouseData?.warehouse
                                    ? {
                                        ...warehouse,
                                        products: updatedProducts,
                                      }
                                    : warehouse
                                )
                              );
                            };

                            return (
                              <tr key={index}>
                                {/* <td>{index + 1}</td> */}
                                <td>{product.name}</td>
                                <td>
                                  <div
                                    className={purchaseStyles.sminputContainer}
                                  >
                                    <FontAwesomeIcon
                                      icon={faMinus}
                                      color={"#009F7F"}
                                      className={purchaseStyles.smicon}
                                      onClick={() =>
                                        handlePackagingChange(
                                          (product.packaging -= 1)
                                        )
                                      }
                                    />
                                    <input
                                      className={purchaseStyles.sminput}
                                      type="number"
                                      name="quantity"
                                      value={product.packaging}
                                      onChange={(e) =>
                                        handlePackagingChange(e.target.value)
                                      }
                                    />
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      color={"#009F7F"}
                                      className={purchaseStyles.smicon}
                                      onClick={() =>
                                        handlePackagingChange(
                                          (product.packaging += 1)
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    style={{
                                      maxWidth: "50px",
                                      fontSize: "12px",
                                    }}
                                    type="number"
                                    name="quantity"
                                    value={product.quantity}
                                    onChange={handleQuantityChange}
                                  />
                                </td>
                                <td>{parseFloat(product.cost).toFixed(0)}</td>

                                {/* <td>
                                  <input
                                    style={{ maxWidth: "70px", fontSize: "12px" }}
                                    type="number"
                                    name="cost"
                                    value={product.total}
                                    onChange={handleCostChange}
                                  />
                                </td> */}
                                <td>{product?.fx}</td>
                                <td>{product?.warehouse_quantity}</td>
                                <td>
                                  <button
                                    onClick={() =>
                                      handleRemoveClick(warehouseIndex, index)
                                    }
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={7} className={"text-end"}>
                              <b>Total</b>
                            </td>
                            <td>
                              {warehouseData?.products?.reduce(
                                (total, product) => total + product.total,
                                0
                              )}
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <PrimaryButton
              name={"Copy Content"}
              onClick={copyToClipboard}
              style={{
                marginLeft: "10px",
              }}
            />
            <div className={"mx-2"}></div>
            <PrimaryButton
              name={loading ? "Ajout en cours..." : "Create Purchase Order"}
              onClick={loading ? null : submit}
              style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
