import React from "react";
import mystyles from "./styles/loading.module.css";

export default function Loading({ title = "Loading..." }) {
  return (
    <div className={mystyles.loadingScreen}>
      <div className={mystyles.loadingContainer}>
        <div className={mystyles.loadingSpinner} />
      </div>
      <div style={{ marginTop: "10px" }}>{title}</div>
    </div>
  );
}
