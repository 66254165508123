import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { queryClient } from "../../../App";

export default function SubcategoryAlerts() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const cols = [
    { title: "Product", internal: "name" },
    { title: "Supplier", internal: "biller.name" },
    { title: "Biller", internal: "supplier.name" },
    {
      title: "Actions",
      internal: "id",
      code: (row, data) => {
        return (
          <div className={"d-flex justify-content-between"}>
            <div
              role={"button"}
              onClick={() => {
                // setShowDelete(true);
                // setSelectedId(row.id);
                deleteObj(row?.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteObj = (id) => {
    axiosInstance.delete("product/" + id + "/").then((response) => {
      // window.location.reload();
      queryClient.invalidateQueries({ queryKey: ["tableData-subcategory-alerts"] });
    });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />
      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"stock/subcategory_alert"}
          pull={setRows}
          title={"Subcategory Alerts"}
          cols={cols}
          rows={rows}
          noCreate={true}
          useReactQuery={"subcategory-alerts"}
        />
      </div>
    </div>
  );
}
