import React from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/inputs";

export default function PaymentSuccess() {
  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      <div className="card p-4">
        <div className="text-center">
          <div className="mb-4">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#4CAF50" fillOpacity="0.1" />
              <circle cx="40" cy="40" r="32" fill="#4CAF50" fillOpacity="0.2" />
              <circle cx="40" cy="40" r="24" fill="#4CAF50" />
              <path
                d="M34 40L38 44L46 36"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <h2 className="text-center mb-4">Paiement effectué avec succès</h2>

          <div className="row mb-4 justify-content-center">
            <div className="col-md-8">
              <div className="card p-3">
                <p className="text-muted mb-0">
                  Votre paiement a été traité avec succès.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center gap-3">
            <div className={"ms-3"}>
              <PrimaryButton
                name={"Page d'accueil"}
                onClick={() => navigate("/")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
