import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice, getMonthName } from "../../../utils/utils";
import Loading from "../../components/loading";
import { PrimaryButton } from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function BillerPayment() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [billerData, setBillerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("wave");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [qrCode, setQrCode] = useState(false);
  const [isPaymentNotFound, setIsPaymentNotFound] = useState(false);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    // Fetch biller data using slug
    axiosInstance
      .get(`/payment/${slug}/`)
      .then((response) => {
        setLoading(false);
        if (response?.data && response?.status === 200) {
          setBillerData(response.data);
        }
        if (response?.response?.status) {
          if (response?.status === 404) {
            setIsPaymentNotFound(true);
          }
        }
        // setBillerData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setIsPaymentNotFound(true);
        setLoading(false);
      });

    // Fetch payment methods
    axiosInstance
      .get("/payment-methods/")
      .then((response) => {
        // Filter only wave and orange money
        const filteredMethods = response.data.filter(
          (method) =>
            method.name === "wave" ||
            method.name === "om" ||
            method.name === "maxit"
        );
        setPaymentMethods(filteredMethods);
        setLoadingPayment(false);
      })
      .catch((error) => {
        setLoadingPayment(false);
      });
  }, [slug, navigate]);

  const handlePayment = () => {
    setLoading(true);
    axiosInstance
      .post(`/billers/payment/${slug}/process/`, {
        payment_method: selectedPayment,
      })
      .then((response) => {
        if (response.data && typeof response.data === "string") {
          if (response.data.startsWith("https")) {
            window.location.href = response.data;
          } else {
            setQrCodeLink(response.data);
            setQrCode(true);
          }
        }
      })
      .catch((error) => {
        console.error("Payment error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const download = () => {
    const commissionAmount = billerData?.month_commission || 0;
    const formattedCommission = formatPrice(commissionAmount);

    axiosInstance
      .get(
        `supplier/${billerData?.supplier_id}/excel/?month=${billerData?.month}&year=${billerData?.year}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${billerData?.supplier_name}-${billerData?.month}-${billerData?.year}-Commission-${formattedCommission}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (blocked) {
    return (
      <div className="container mt-5">
        <div className="card p-4 text-center">
          <h2 className="mb-3">Paiement non disponible</h2>
          <p className="text-muted mb-4">
            Ce paiement a déjà été effectué ou n'existe plus.
          </p>
          <p>
            Veuillez vérifier le numéro de facture ou contacter le support
            client.
          </p>
          <div className={"mt-3"}>
            <PrimaryButton
              name={"Page d'accueil"}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    );
  }

  if (isPaymentNotFound || !billerData) {
    return (
      <div className="container mt-5">
        <div className="card p-4 text-center">
          <h2 className="mb-3">Paiement non disponible</h2>
          <p className="text-muted mb-4">
            Ce paiement a déjà été effectué ou n'existe plus.
          </p>
          <p>
            Veuillez vérifier le numéro de facture ou contacter le support
            client.
          </p>
          <div className={"mt-3"}>
            <PrimaryButton
              name={"Page d'accueil"}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="card p-4">
        <h2 className="text-center mb-4">{billerData?.supplier_name}</h2>

        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card p-3">
              <h5>Commission du mois de {getMonthName(billerData?.month)}</h5>
              <h3 className="text-primary">
                {formatPrice(billerData?.month_commission)} FCFA
              </h3>
              <small>
                {getMonthName(billerData?.month)} {billerData?.year}
              </small>
              <PrimaryButton
                name={"Télécharger Excel"}
                onClick={download}
                className="btn  mt-2"
                style={{ color: "blue", cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <h5>Commission totale des mois impayés</h5>
              <h3 className="text-success">
                {formatPrice(billerData?.total_commission)} FCFA
              </h3>
            </div>
          </div>
        </div>

        {!qrCode ? (
          <>
            <div className="payment-methods mt-4">
              <h5 className="mb-3">Sélectionnez un moyen de paiement:</h5>
              {loadingPayment ? (
                <Loading />
              ) : (
                <div className="d-flex flex-wrap justify-content-center gap-4">
                  {paymentMethods.map(
                    (method, index) =>
                      method.is_active && (
                        <div
                          key={index}
                          className={`payment-option p-2 text-center ${
                            selectedPayment === method.name
                              ? "border border-primary"
                              : ""
                          }`}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedPayment === method.name
                                ? "#f0f0f0"
                                : "transparent",
                            padding: "10px",
                            borderRadius: "5px",
                            flex: "1",
                            textAlign: "center",
                            border:
                              selectedPayment === method.name
                                ? "2px solid #000"
                                : "none",
                          }}
                          onClick={() => setSelectedPayment(method.name)}
                        >
                          <img
                            src={`/assets/${method.name}.png`}
                            alt={method.name}
                            style={{
                              height: "30px",
                              width: "auto",
                              display: "block",
                              margin: "auto",
                            }}
                            className="mb-1"
                          />
                          <div style={{ fontSize: "14px" }}>{method.name}</div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>

            <div className={"mt-3"}>
              <PrimaryButton
                name={"Payer maintenant"}
                onClick={handlePayment}
                disabled={loading || loadingPayment}
              />
            </div>
          </>
        ) : (
          //     onClick={handlePayment}
          //     disabled={loading || loadingPayment}
          //   >
          //     {loading ? "Traitement..." : "Payer maintenant"}
          //   </button>
          // </>
          <div className="text-center">
            <h5>Scannez ce QR code avec votre application</h5>
            <h6>Orange money / Wave</h6>
            <img
              className="mx-auto d-block mt-3"
              src={`data:image/png;base64,${qrCodeLink}`}
              alt="QR Code de paiement"
              style={{ maxWidth: "300px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
