import React, { useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import { formatPrice } from "../../../utils/utils";
import { getPaimentColor } from "../../components/utils";
export default function BillerPaymentHistory({ paid }) {
  const [rows, setRows] = useState([]);

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const cols = [
    {
      title: "Biller Name",
      internal: "supplier",
      filter: true,
      code: (data) => data?.supplier?.name || "-",
    },
    {
      title: "Phone",
      internal: "supplier",
      filter: true,
      code: (data) =>
        data?.supplier?.contact_phone_admin ||
        data?.supplier?.contact_phone ||
        "-",
    },
    {
      title: "Period",
      internal: "month",
      code: (data) => `${monthNames[data.month - 1]} ${data.year}`,
    },
    {
      title: "Commission",
      internal: "total_commission",
      code: (data) => formatPrice(data.month_commission),
    },
    {
      title: "Amount Paid",
      internal: "total_payed",
      code: (data) => formatPrice(data.total_payed),
    },
    {
      title: "Status",
      internal: "paid",
      //   filter: true,
      code: (data) => (
        <span
          style={{
            color:
              data.paid || data.month_commission === 0 ? "#4CAF50" : "#EF4444",
            fontWeight: "bold",
          }}
        >
          {data.paid || data.month_commission === 0 ? "Payé" : "Non payé"}
        </span>
      ),
    },
    {
      title: "Paiment",
      internal: "paiment_mode",
      code: (data, row) => getPaimentColor(data.paiment_mode),
      filter: true,
    },
    {
      title: "Updated At",
      internal: "updated_at",
      //afficher la date et l'heure
      code: (data) => new Date(data.updated_at).toLocaleString(),
    },
    {
      title: "Created At",
      internal: "created_at",
      code: (data) => new Date(data.created_at).toLocaleString(),
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4 pb-4`}>
        <TableView
          fetchUrl={"billers/payment/history"}
          pull={setRows}
          defaultFilters={{
            paid: true,
          }}
          title={"Biller Payment History"}
          cols={cols}
          rows={rows}
          withIndex={true}
          limit={25}
          useReactQuery={"biller-payment-history"}
        />
      </div>
    </div>
  );
}
