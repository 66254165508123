import React, { useEffect, useState } from "react";
import BootStrapModal from "react-bootstrap/Modal";
import styles from "../../products/products.module.css";
import { formatPrice } from "../../../utils/utils";

export default function OrderDetail({ showModal, setShowModal, order }) {
  const [variantTotal, setVariantTotal] = useState(0);

  useEffect(() => {
    if (!order) return;
    let total_variant_price = 0;
    order?.items?.forEach((item) => {
      let item_variant_price = 0;
      item?.variant_items?.forEach((variant) => {
        item_variant_price += variant.variant_item.price * item.qty;
      });
      item.variant_cost = item_variant_price;
      total_variant_price += item_variant_price;
    });
    setVariantTotal(total_variant_price);
  }, [order]);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return {
          label: "En attente",
          color: "#fa9f43",  // Orange
          bgColor: "#fff5e6"
        };
      case 1:
        return {
          label: "Confirmée",
          color: "#00cfe8",  // Cyan
          bgColor: "#e6fcff"
        };
      case 2:
        return {
          label: "En préparation",
          color: "#7367f0",  // Purple
          bgColor: "#eeedfd"
        };
      case 3:
        return {
          label: "En livraison",
          color: "#1890ff",  // Blue
          bgColor: "#e6f7ff"
        };
      case 4:
        return {
          label: "Livrée",
          color: "#28c76f",  // Green
          bgColor: "#e6fff0"
        };
      case 5:
        return {
          label: "Annulée",
          color: "#ea5455",  // Red
          bgColor: "#ffe6e6"
        };
      default:
        return {
          label: "Inconnu",
          color: "#82868b",  // Grey
          bgColor: "#f0f0f0"
        };
    }
  };

  const getPaymentMode = (mode) => {
    switch (mode) {
      case "cash":
        return {
          label: "Paiement en espèces",
          image: "../assets/cash.png",
          color: "#28c76f"
        };
      case "wave":
        return {
          label: "Wave",
          image: "../assets/wave.png",
          color: "#4484FF"
        };
      case "om":
        return {
          label: "Orange Money",
          image: "../assets/om.png",
          color: "#FF7900"
        };
      case "maxit":
        return {
          label: "MaxIt",
          image: "../assets/maxit.png",
          color: "#00f"
        };
      default:
        return {
          label: mode,
          image: "../assets/default.png",
          color: "#82868b"
        };
    }
  };

  return (
    <BootStrapModal
      show={showModal}
      centered={true}
      onBackdropClick={() => setShowModal(false)}
      onHide={() => setShowModal(false)}
      size="lg"
    >
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="m-0">Détails de la commande ({order?.reference})</h5>
          <div
            className="px-3 py-1 rounded-pill"
            style={{
              color: getStatus(order?.status).color,
              backgroundColor: getStatus(order?.status).bgColor,
              fontSize: "0.9em",
              fontWeight: "500"
            }}
          >
            {getStatus(order?.status).label}
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid #CCC",
          }}
        >
          {order?.items?.map((item, index) => (
            <div key={index} className={"my-2 px-3 pt-2"}>
              <div className={"d-flex justify-content-between align-items-center"}>
                <img
                  src={item?.image?.image || "/placeholder.jpg"}
                  alt={item.product_name}
                  height={"100px"}
                  width={"20%"}
                  style={{
                    objectFit: "contain",
                  }}
                />
                <div style={{ width: "75%" }}>
                  <div>
                    <div className="fw-bold">{item.product_name}</div>
                    {item.variant_items.length > 0 && (
                      <div style={{ fontSize: "12px", color: "#616161" }}>
                        {item.variant_items.map((variant, idx) => (
                          <span key={idx}>
                            {variant.variant_name}: {variant.variant_item.name}
                            {idx < item.variant_items.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={"d-flex justify-content-between align-items-center mt-2"}>
                    <div>
                      <span className="fw-bold">
                        {formatPrice(item.price + (item.variant_cost ? item.variant_cost : 0) / item.qty)} FCFA
                      </span>
                      <span className="ms-3">Quantité: {item.qty}</span>
                    </div>
                    <div className="fw-bold">
                      {formatPrice((item.price * item.qty) + (item.variant_cost || 0))} FCFA
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-3">
          <div className="px-3 mb-3">
            <div className="d-flex justify-content-between mb-2">
              <div>Sous-total produits:</div>
              <div>{formatPrice(order?.sub_total + variantTotal)} FCFA</div>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>Mode de paiement:</div>
              <div className="d-flex align-items-center">
                <img
                  src={getPaymentMode(order?.paiment_mode).image}
                  alt={getPaymentMode(order?.paiment_mode).label}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    objectFit: "contain"
                  }}
                />
                <span style={{ color: getPaymentMode(order?.paiment_mode).color }}>
                  {getPaymentMode(order?.paiment_mode).label}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-2">
              <div>Frais de livraison:</div>
              <div>{formatPrice(order?.fee)} FCFA</div>
            </div>

            {order?.points_used > 0 && (
              <div className="d-flex justify-content-between mb-2" style={{ color: "#fa9f43" }}>
                <div>Points utilisés:</div>
                <div>-{order.points_used} points</div>
              </div>
            )}

            {order?.points_earned > 0 && (
              <div className="d-flex justify-content-between mb-2" style={{ color: "#28c76f" }}>
                <div>Points gagnés:</div>
                <div>+{order.points_earned} points</div>
              </div>
            )}

            <div className="d-flex justify-content-between mt-3 pt-2 border-top">
              <div className="fw-bold">Total:</div>
              <div className="fw-bold" style={{ fontSize: "1.2em", color: order?.points_used > 0 ? "#fa9f43" : undefined }}>
                {formatPrice((order?.sub_total + order?.fee + variantTotal) - order?.points_used)} FCFA
              </div>
            </div>
          </div>

          {order?.indications && (
            <div className="px-3 py-2 bg-light rounded">
              <div className="fw-bold mb-1">Instructions de livraison:</div>
              <div>{order.indications}</div>
            </div>
          )}
        </div>
      </div>
    </BootStrapModal>
  );
}
