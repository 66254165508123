import React, { useEffect, useState } from "react";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";
import Loading from "../../components/loading";
import * as XLSX from "xlsx"; // Importation de xlsx pour générer les fichiers Excel
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";

export default function AutoMultiTransfer() {
  // const [timer, setTimer] = useState(null);
  const [foundStock, setFoundStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState([]);
  const [form, setForm] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // État pour la page actuelle
  const [totalPages, setTotalPages] = useState(0); // État pour le nombre total de pages

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get("warehouse/").then((response) => {
      setWarehouses(response.data);
    });
  }, []);

  const handleStockTransfer = async (fromWarehouseId, toWarehouseId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`auto_transfert_stock/`, {
        params: {
          warehouse_from: fromWarehouseId,
          warehouse_to: toWarehouseId,
          page: currentPage, // Ajoutez la page actuelle ici
        },
      });

      if (response.data.results.length > 0) {
        setTotalPages(Math.ceil(response.data.count / 500)); // Mettez à jour le nombre total de pages
        let received = response.data.results; // Utilisez les résultats paginés
        const newItems = received.map((item) => ({
          id: item?.product.id,
          quantity: item?.quantity_to_transfer,
          name: item?.product.name,
          stock_from: item?.warehouse_from_stock,
          stock_to: item?.warehouse_to_stock,
          warehouse_to_sold: item?.warehouse_to_sold,
          warehouse_from_sold: item?.warehouse_from_sold,
          total_cost: item?.product.cost * item?.quantity_to_transfer,
        }));

        setSelectedStock([...selectedStock, ...newItems]);
      } else {
        console.log("Aucun produit à transférer.");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des produits à transférer:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (form["from_warehouse"] !== undefined) {
      setSelectedStock([]);
      handleStockTransfer(form["from_warehouse"], form["warehouse"]);
    }
  }, [form["warehouse"], currentPage]); // Ajoutez currentPage comme dépendance

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const back = () => {
    // window.location.replace("/admin/stock");
    navigate("/admin/stock", { replace: true });
  };

  const submit = () => {
    let error = false;
    selectedStock.forEach((item) => {
      if (item?.quantity > item?.original_quantity) {
        toast(
          "Max quantity is " + item?.original_quantity + ` for ${item?.name}`,
          ToastTypes.ERROR
        );
        error = true;
      }
    });
    if (error) return;

    // console.log(selectedStock);
    // console.log(form["warehouse"]);
    axiosInstance
      .post("auto_transfert_stock/auto_multi_transfer/", {
        to_warehouse: form["warehouse"],
        from_warehouse: form["from_warehouse"],
        stock: selectedStock.map((item) => ({
          id: item?.id,
          quantity: item?.quantity,
          expiry_date: item?.expiry_date,
          total_cost: item?.total_cost * item?.quantity,
        })),
      })
      .then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-stock-list"],
        });
        queryClient.invalidateQueries({
          queryKey: ["tableData-transfere-history-list"],
        });
        back();
      });
  };

  const removeProduct = (index) => {
    const updatedStock = [...selectedStock];
    updatedStock.splice(index, 1);
    setSelectedStock(updatedStock);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(selectedStock);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock");
    XLSX.writeFile(workbook, "stock_transfer.xlsx");
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Transfer Stock</div>
          <div></div>
        </div>

        <div className={styles.crudInputs}>
          {/* from warehouse to warehouse input  */}
          <div className={`${styles.createContainer} p-4`}>
            <div className={""}>
              <div className={"mb-2"}>
                <SelectInput
                  form={form}
                  setForm={setForm}
                  name={"From Warehouse"}
                  internalName={"from_warehouse"}
                  options={warehouses}
                />
                <div className={"mb-2"} />
                <SelectInput
                  form={form}
                  setForm={setForm}
                  name={"To Warehouse"}
                  internalName={"warehouse"}
                  options={warehouses}
                />
              </div>
            </div>
          </div>

          {/* Table for displaying products */}
          <div className={`${styles.createContainer} p-4`}>
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Stock 1</th>
                  <th>FX (1)</th>
                  <th>Stock 2</th>
                  <th>FX (2)</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {selectedStock.length > 0 ? (
                      selectedStock.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.name}</td>
                          <td>{item?.stock_from}</td>
                          <td>{item?.warehouse_from_sold}</td>
                          <td>{item?.stock_to}</td>
                          <td>{item?.warehouse_to_sold}</td>
                          <td>
                            <input
                              style={{ maxWidth: "90px" }}
                              type="number"
                              name="quantity"
                              value={item?.quantity}
                              onChange={(event) => {
                                const updatedStock = [...selectedStock];
                                updatedStock[index] = {
                                  ...item,
                                  quantity: parseInt(event.target.value),
                                };
                                setSelectedStock(updatedStock);
                              }}
                            />
                          </td>
                          <td>
                            <button
                              onClick={() => removeProduct(index)}
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                color: "#ff0000",
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          Aucun produit à transférer.
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className={styles.paginationContainer}>
            <span>
              Page {currentPage} sur {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="btn btn-primary m-3"
            >
              Load More
            </button>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton name={"Transferer"} onClick={submit} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={"Télécharger la liste en Excel"}
              onClick={downloadExcel}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
