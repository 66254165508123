import React, { useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import "./schema.css";
import { useQuery } from "@tanstack/react-query";

function Schema() {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState("");

  // Queries
  const schemaQuery = useQuery({
    queryKey: ["schema-ai"],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/schema/`);
      setContent(data?.schema_description);
      setContent(data?.schema_description);
      setLastSavedContent(data?.schema_description);
      return data?.schema_description;
    },
  });
  const handleSave = async () => {
    setIsSaving(true);
    try {
      await axiosInstance.post("/schema/", { schema_description: content });
      setLastSavedContent(content);
      // Afficher une notification de succès
      const notification = document.getElementById("save-notification");
      notification.style.opacity = "1";
      setTimeout(() => {
        notification.style.opacity = "0";
      }, 2000);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde:", error);
      // Afficher une notification d'erreur
      alert("Erreur lors de la sauvegarde. Veuillez réessayer.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleUndo = () => {
    if (
      window.confirm(
        "Voulez-vous annuler toutes les modifications non sauvegardées ?"
      )
    ) {
      setContent(lastSavedContent);
    }
  };

  const hasUnsavedChanges = content !== lastSavedContent;

  return (
    <div className="d-flex">
      <AdminSidebar />
      <AdminHeader />
      <div className={`${styles.container} schema-container`}>
        <div className="editor-header">
          <h4>Schéma d'apprentissage de l'IA</h4>
          <div className="editor-actions">
            <button
              className="btn btn-outline-secondary"
              onClick={handleUndo}
              disabled={!hasUnsavedChanges || schemaQuery?.isLoading}
            >
              <FontAwesomeIcon icon={faUndo} className="me-2" />
              Annuler les modifications
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSave}
              disabled={
                !hasUnsavedChanges || schemaQuery?.isLoading || isSaving
              }
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              {isSaving ? "Sauvegarde..." : "Sauvegarder"}
            </button>
          </div>
        </div>

        <div className="editor-container">
          {schemaQuery?.isLoading ? (
            <div className="text-center p-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Chargement...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="line-numbers">
                {content.split("\n").map((_, i) => (
                  <div key={i + 1} className="line-number">
                    {i + 1}
                  </div>
                ))}
              </div>
              <textarea
                className="notepad-editor"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Écrivez votre schéma d'apprentissage ici..."
                spellCheck="false"
                onScroll={(e) => {
                  const lineNumbers = document.querySelector(".line-numbers");
                  if (lineNumbers) {
                    lineNumbers.scrollTop = e.target.scrollTop;
                  }
                }}
              />
            </>
          )}
        </div>

        <div id="save-notification" className="save-notification">
          Modifications sauvegardées avec succès !
        </div>
      </div>
    </div>
  );
}

export default Schema;
