import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { faClipboard, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../components/utils";
import { formatPrice } from "../../../utils/utils";
import {
  ToastTypes,
  toast,
} from "../../../components/ajonjolib/toasts/toast/toast";
import usePermissions from "../../../hooks/usePermissions";

export default function PurchaseOrderList() {
  const [rows, setRows] = useState([]);
  // const permissions = parseInt(localStorage.getItem("permissions"));
  const permissions = usePermissions();

  const navigate = useNavigate();

  const manual = () => {
    navigate("/admin/purchase_order/manual");
  };

  const show = (id) => {
    const found = rows.find((obj) => obj.id === id);
    navigate("/admin/purchase_order/view", { state: found });
  };

  const edit = (id) => {
    const found = rows.find((obj) => obj.id === id);
    if (found.status !== 0) {
      return toast("Only pending purchases can be updated!", ToastTypes.ERROR);
    }
    navigate("/admin/purchase_order/edit", { state: found });
  };

  const cols = [
    {
      title: "Actions",
      buttons: [
        {
          icon: faClipboard,
          color: "#9EA3AC",
          onClick: show,
        },
        {
          icon: faEdit,
          color: "#ecd225",
          onClick: edit,
        },
      ],
    },
    {
      title: "Date",
      internal: "timestamp",
      filterInternal: "date",
      filter: true,
      dateFilter: true,
      code: (data, row) => formatTimestamp(row),
    },
    { title: "Supplier", internal: "biller_name", filter: true },
    {
      title: "Status",
      internal: "status",
      filter: true,
      code: (data, row) => {
        if (row === 0) {
          return (
            <div
              style={{
                borderRadius: "6px",
                backgroundColor: "#ecd225",
                textAlign: "center",
                color: "white",
              }}
            >
              <b>{"Pending"}</b>
            </div>
          );
        } else if (row === 1) {
          return (
            <div
              style={{
                borderRadius: "6px",
                backgroundColor: "#ec8f25",
                textAlign: "center",
                color: "white",
              }}
            >
              <b>{"In Process"}</b>
            </div>
          );
        } else if (row === 2) {
          return (
            <div
              style={{
                borderRadius: "6px",
                backgroundColor: "#39e082",
                textAlign: "center",
                color: "white",
              }}
            >
              <b>{"Received"}</b>
            </div>
          );
        } else {
          return (
            <div
              style={{
                borderRadius: "6px",
                backgroundColor: "#FF0000",
                textAlign: "center",
                color: "white",
              }}
            >
              <b>{"Cancelled"}</b>
            </div>
          );
        }
      },
    },
    { title: "Warehouse", internal: "warehouse_name", filter: true },
    {
      title: "Total",
      internal: "total",
      filter: true,
      // code: (row, data) => {
      //   return formatPrice(data);
      // },
      code: (row, data) => {
        const priceStyle = row.price_changed
          ? { color: "#FF0000", fontWeight: "bold" } // Highlight in red if price changed
          : {}; // Default style
        return <span style={priceStyle}>{formatPrice(data)}</span>;
      },
    },
    { title: "Payed", internal: "payed", isBoolean: true, filter: true },
    { title: "Reference Number", internal: "reference", filter: true },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          pull={setRows}
          fetchUrl={"purchase_order"}
          title={"List of Products to Order"}
          cols={cols}
          rows={rows}
          noCreate={true}
          useReactQuery={"pourchase-list"}
        >
          {permissions !== 2 && (
            <div className={`d-flex mt-3 justify-content-end`}>
              <div
                className={`${styles.tableButton}`}
                role={"button"}
                onClick={() => manual()}
              >
                Manual Purchase
              </div>
            </div>
          )}
        </TableView>
      </div>
    </div>
  );
}
